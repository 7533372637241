import React from "react"
import { Link } from "gatsby"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { Container, Row, Col, Media } from "react-bootstrap"
import slcLogo from "../../images/slc-logo.png"
import slcLogo1 from "../../images/solution-logo-1.png"
import slcLogo2 from "../../images/project-adv.png"
import alarmIcon from "../../images/f-alarm.png"
import programIcon from "../../images/f-program.png"
import coinIcon from "../../images/f-coin-count.png"
import alarmstatsIcon from "../../images/f-alarm-stats.png"
import resetcoinIcon from "../../images/f-reset-coins.png"
import programhistoryIcon from "../../images/f-program-history.png"
import alarmhistoryIcon from "../../images/f-alarm-history.png"
import setcreditIcon from "../../images/f-reset-coins_1.png"
import setpriceIcon from "../../images/f-set-price-list.png"
import machineStatus from "../../images/machine-status.svg"
import programstatsIcon from "../../images/f-program-statistics.png"
import creditIcon from "../../images/f-credit.png"
import campaignIcon from "../../images/f-set-campaign.png"
import totalCapacity from "../../images/total-capacity.svg"
import alarmStatus from "../../images/alarms.svg"
import consumptionStatus from "../../images/consumption-status.png"
import currentprogramStatus from "../../images/current-program.svg"
import overall from "../../images/overall.svg"
import prolacsSoftware from "../../images/hiw/prolacs-software.png"
import prolacsAmazon from "../../images/hiw/prolacs-amazon.png"
import prolacsDB from "../../images/hiw/database.png"
import proalcsGateway from "../../images/hiw/gateways.png"
import dowDoc from "../../images/download-doc.png"
import centralPayment from "../../images/hiw/central-payment.png"
import prolacsWasher from "../../images/hiw/washer-open.png"
import prolacsDryer from "../../images/hiw/dryer-open.png"
import prolacsLPG from "../../images/hiw/lpg.png"
import prolacsElectricity from "../../images/hiw/electricity.png"
import prolacsWater from "../../images/hiw/water.png"
import prolacsChemical from "../../images/hiw/chemical.png"
import prolacsSteam from "../../images/hiw/steam.png"
import prolacsAir from "../../images/hiw/air.png"



const slc = () => (
  <Layout>
    <SEO title="Solutions"
    description="Prolacs has a new generation IoT solutions for laundries designed with industry 4.0 inspiration." />
    <Container>
      <div className='prolacs-divider-title' style={{ marginTop: '0', backgroundColor: '#FFFFFF', display: 'flex', alignItems: 'baseline' }}>
        <br></br>
        <br></br>
        <Link className='link-breadcrumb' to='/solutions'><h2 style={{ fontSize: '19px' }}>Solutions  /</h2> </Link>
        <h1 style={{ fontSize: '20px', paddingLeft: '5px' }}> Smart Laundromat Control </h1>
      </div>


      <Row style={{ marginBottom: '200px', marginTop: '50px' }}>
        <Col sm>
          <div className='solution-header'>
            <img src={slcLogo} alt="Smart Laundromat Control" />
          </div>
        </Col>
        <Col sm>
          <h1>Prolacs SLC</h1>
          <h2>Smart Laundromat Control</h2>
          <p>SLC is the professional self-service laundromats solution that lets you control your businesses end to end. It has a modular and scalable structure suitable for every brand of machines. It increases the profitability of your business with modules such as consumption management, campaign management and overall equipment efficiency <b>(OEE)</b>.</p>
        </Col>
      </Row>

      <Row>
        <Col sm style={{ textAlign: 'center' }}>
          <img style={{ width: '25%' }} src={slcLogo1} alt="about smart laundromat control prolacs" />
          <h1>About Solution</h1>
          <p>Remote monitoring and management of area independent washing facilities. Infrastructure monitoring such as washing and squeezing costs used in laundry, cylinder iron, folding fuel, heat dosing systems, steam boilers are the main objectives of the solution.
          </p>
        </Col>
        <Col sm style={{ textAlign: 'center' }}>
          <img style={{ width: '25%' }} src={slcLogo2} alt="about smart laundromat control prolacs" />
          <h1>Solution Advantage</h1>
          <p>With Prolacs, consumption resources can be monitored in real-time while tracking efficiency of operator, maintenance personel, machines, production line and/or production center.
          </p>
        </Col>
      </Row>
      <div className='prolacs-divider-title'>
        <br></br>
        <br></br>
        <h1>Features</h1>
      </div>
      <Row >
        <Col sm style={{ textAlign: 'center' }}>
          <img src={alarmIcon} alt="alarm smart laundromat control prolacs" />
          <h4>Alarms</h4>
          <p> Monitor active alarms</p>
        </Col>
        <Col sm style={{ textAlign: 'center' }}>
          <img src={programIcon} alt="program smart laundromat control prolacs" />
          <h4>Program</h4>
          <p> Monitor running program status</p>
        </Col>
        <Col sm style={{ textAlign: 'center' }}>
          <img src={coinIcon} alt="coin count smart laundromat control prolacs" />
          <h4>Coin Count</h4>
          <p> Monitor coin count in the box</p>
        </Col>
      </Row>
      <Row>
        <Col sm style={{ textAlign: 'center' }}>
          <img src={alarmstatsIcon} alt="alarm smart laundromat control prolacs" />
          <h4>Alarm Stats</h4>
          <p> Analyze alarms history by charts and tables</p>
        </Col>
        <Col sm style={{ textAlign: 'center' }}>
          <img src={resetcoinIcon} alt="program smart laundromat control prolacs" />
          <h4>Reset Coin</h4>
          <p> Reset coins in the box</p>
        </Col>
        <Col sm style={{ textAlign: 'center' }}>
          <img src={programhistoryIcon} alt="coin count smart laundromat control prolacs" />
          <h4>Program History</h4>
          <p> Monitor executed program history</p>
        </Col>
      </Row>
      <Row>
        <Col sm style={{ textAlign: 'center' }}>
          <img src={alarmhistoryIcon} alt="alarm smart laundromat control prolacs" />
          <h4>Alarm History</h4>
          <p>Monitor triggered alarm history</p>
        </Col>
        <Col sm style={{ textAlign: 'center' }}>
          <img src={setcreditIcon} alt="program smart laundromat control prolacs" />
          <h4>Set Credit</h4>
          <p> Set current credit on machine</p>
        </Col>
        <Col sm style={{ textAlign: 'center' }}>
          <img src={setpriceIcon} alt="coin count smart laundromat control prolacs" />
          <h4>Set Price List</h4>
          <p>Set program prices on machine</p>
        </Col>
      </Row>
      <Row>
        <Col sm style={{ textAlign: 'center' }}>
          <img src={programstatsIcon} alt="alarm smart laundromat control prolacs" />
          <h4>Program Statistic</h4>
          <p>Analyze executed programs by charts and tables</p>
        </Col>
        <Col sm style={{ textAlign: 'center' }}>
          <img src={creditIcon} alt="program smart laundromat control prolacs" />
          <h4>Credit</h4>
          <p>Set program prices on machine</p>
        </Col>
        <Col sm style={{ textAlign: 'center' }}>
          <img src={campaignIcon} alt="coin count smart laundromat control prolacs" />
          <h4>Set Campaign</h4>
          <p> Set campaign for special days and hours</p>
        </Col>
      </Row>

      <div className='prolacs-divider-title'>
        <br></br>
        <br></br>
        <h1>More Features</h1>
      </div>
      <Row >
        <Col sm>
          <Media>
            <Media.Body>
              <h4>Machine Status</h4>
              <h5 style={{ fontSize: 'large' }}>Current status of each machine.</h5>
              <p>Running, cancel, pause, idling etc.</p>
            </Media.Body>
            <img
              className="ml-3"
              src={machineStatus}
              alt="Machine Status"
            />
          </Media>
        </Col>
        <Col sm>
          <Media>
            <Media.Body>
              <h4>Total Capacity Utilization</h4>
              <p>See total capacity utilization in real time.</p>
            </Media.Body>
            <img
              className="ml-3"
              src={totalCapacity}
              alt="Total Capacity Utilization"
            />
          </Media>
        </Col>
      </Row>
      <Row>
        <Col sm>
          <Media>
            <Media.Body>
              <h4>Alarms</h4>
              <h5 style={{ fontSize: 'large' }}>50+ alarms to alert when required.</h5>
              <p>Emergency Stop, Water Fill Fail, Inverter Alarm, Power Fail etc.</p>
            </Media.Body>
            <img
              className="ml-3"
              src={alarmStatus}
              alt="Alarms"
            />
          </Media>
        </Col>
        <Col sm>
          <Media>
            <Media.Body>
              <h4>Consumption Status</h4>
              <h5 style={{ fontSize: 'large' }}>Measures consumption such as energy, water, steam and chemicals.</h5>
              <p>Machine, Laundry or Production Line</p>
            </Media.Body>
            <img
              className="ml-3"
              src={consumptionStatus}
              alt="Consumption Status"
            />
          </Media>
        </Col>
      </Row>
      <Row>
        <Col sm>
          <Media>
            <Media.Body>
              <h4>Current Program on Machine</h4>
              <p>Allows you to see the current program running on machines in real-time.</p>
            </Media.Body>
            <img
              className="ml-3"
              src={currentprogramStatus}
              alt="Current Program on Machine"
            />
          </Media>
        </Col>
        <Col sm>
          <Media>
            <Media.Body>
              <h4>Overall Equipment Effectiveness (OEE)</h4>
              <p>Instead of unnecessary purchases, it allows to increase the performance of the machines and equipment available.</p>
            </Media.Body>
            <img
              className="ml-3"
              src={overall}
              alt="Overall Equipment Effectiveness (OEE)"
            />
          </Media>
        </Col>
      </Row>


      <div className='prolacs-divider-title'>
        <br></br>
        <br></br>
        <h1>How It Works?</h1>
      </div>
      <Row style={{ textAlign: 'center', alignItems: 'flex-end' }}>
        <Col sm>
          <img src={prolacsSoftware} alt="prolacs software" /><br />
          <h5 style={{ backgroundColor: '#333333', color: 'white'}}>Prolacs</h5>
          <svg className='middle-infinite-dash' width="50px" height="50px" viewBox="0 0 50 50"><g class="line" data-name="Layer 2"><path fill="none" stroke="gray" stroke-miterlimit="10" stroke-width="2" stroke-dasharray="12" d="M1 166.96V0" data-name="Layer 1" /></g></svg>
        </Col>
      </Row>
      <Row className='smart-laundromat-scheme-row' style={{ textAlign: 'center', alignItems: 'flex-end' }}>
        <Col>
          <img src={prolacsAmazon} alt="prolacs software" /><br />
          <h5 style={{ backgroundColor: '#333333', color: 'white', marginLeft:'20%', marginRight:'20%' }}>Cloud</h5>
        </Col>
        <Col>
          <img src={prolacsDB} alt="prolacs software" /><br />
          <h5 style={{ backgroundColor: '#333333', color: 'white' , marginLeft:'20%', marginRight:'20%' }}>Database</h5>
        </Col>

      </Row>
      <Row style={{ textAlign: 'center', alignItems: 'flex-end' }}>
        <Col sm>
          <svg className='middle-infinite-dash' width="50px" height="50px" viewBox="0 0 50 50"><g class="line" data-name="Layer 2"><path fill="none" stroke="gray" stroke-miterlimit="10" stroke-width="2" stroke-dasharray="12" d="M1 166.96V0" data-name="Layer 1" /></g></svg>
        </Col>
      </Row>
      <Row className='smart-laundromat-scheme-row' style={{ textAlign: 'center', alignItems: 'flex-end' }}>
        <Col sm style={{ textAlign: '-webkit-center' }}>
          <img src={proalcsGateway} alt="prolacs gateway" /><br />
          <h5 style={{ backgroundColor: '#333333', color: 'white', width: '25vw', marginLeft:'20%', marginRight:'20%' }}>Gateways</h5>
        </Col>
      </Row>
      <Row style={{ textAlign: 'center', alignItems: 'flex-end' }}>
        <Col sm>
          <svg className='middle-infinite-dash' width="50px" height="50px" viewBox="0 0 50 50"><g class="line" data-name="Layer 2"><path fill="none" stroke="gray" stroke-miterlimit="10" stroke-width="2" stroke-dasharray="12" d="M1 166.96V0" data-name="Layer 1" /></g></svg>
        </Col>
      </Row>
      <Row className='smart-laundromat-scheme-row' style={{ textAlign: 'center', alignItems: 'flex-end' }}>
        <Col style={{ textAlign: '-webkit-center' }}>
          <img src={centralPayment} alt="prolacs gateway" /><br />
          <h5 style={{ backgroundColor: '#333333', color: 'white', marginLeft:'20%', marginRight:'20%' }}>Central Payment System</h5>
        </Col>
        <Col style={{ textAlign: '-webkit-center' }}>
          <img src={prolacsWasher} alt="prolacs gateway" /><br />
          <h5 style={{ backgroundColor: '#333333', color: 'white' , marginLeft:'20%', marginRight:'20%'}}>Washing Machines</h5>
        </Col>
        <Col style={{ textAlign: '-webkit-center' }}>
          <img src={prolacsDryer} alt="prolacs gateway" /><br />
          <h5 style={{ backgroundColor: '#333333', color: 'white', marginLeft:'20%', marginRight:'20%' }}>Dryer Machines</h5>
        </Col>
      </Row>
      <Row style={{ textAlign: 'center', alignItems: 'flex-end' }}>
        <Col sm>
          <svg className='middle-infinite-dash' width="50px" height="50px" viewBox="0 0 50 50"><g className="line" data-name="Layer 2"><path fill="none" stroke="gray" stroke-miterlimit="10" stroke-width="2" stroke-dasharray="12" d="M1 166.96V0" data-name="Layer 1" /></g></svg>
        </Col>
      </Row>
      <Row className='smart-laundromat-scheme-row' style={{ textAlign: 'center', alignItems: 'flex-end' }}>
        <Col style={{ textAlign: '-webkit-center' }}>
          <img src={prolacsLPG} alt="prolacs gateway" /><br />
          <h5 style={{ backgroundColor: '#333333', color: 'white' }}>LPG</h5>
        </Col>
        <Col style={{ textAlign: '-webkit-center' }}>
          <img src={prolacsElectricity} alt="prolacs gateway" /><br />
          <h5 style={{ backgroundColor: '#333333', color: 'white' }}>Electricity</h5>
        </Col>
        <Col style={{ textAlign: '-webkit-center' }}>
          <img src={prolacsWater} alt="prolacs gateway" /><br />
          <h5 style={{ backgroundColor: '#333333', color: 'white' }}>Water</h5>
        </Col>
        <Col style={{ textAlign: '-webkit-center' }}>
          <img src={prolacsChemical} alt="prolacs gateway" /><br />
          <h5 style={{ backgroundColor: '#333333', color: 'white' }}>Chemical</h5>
        </Col>
        <Col style={{ textAlign: '-webkit-center' }}>
          <img src={prolacsSteam} alt="prolacs gateway" /><br />
          <h5 style={{ backgroundColor: '#333333', color: 'white' }}>Steam</h5>
        </Col>
        <Col style={{ textAlign: '-webkit-center' }}>
          <img src={prolacsAir} alt="prolacs gateway" /><br />
          <h5 style={{ backgroundColor: '#333333', color: 'white' }}>Air</h5>
        </Col>
      </Row>
      <br />
      <br />
      <br />
      {/* <Row>
        <Col className='product-detail-doc-name' style={{textAlign:'center'}}>
          <img className='product-detail-doc' src={dowDoc} alt=" download full smart laundromat control doc" />
          <h1>Download the full paper</h1>
        </Col>
      </Row> */}
    </Container>
  </Layout >
)

export default slc
